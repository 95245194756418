// Login.js
import React, { useState } from "react";
import api from "../../Api";
import logo from "../../image/logo-change.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { email };
      const response = await api.forgotPassword(userData);
      console.log(response);
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  return (
    <div>
      <div className="login_inform login_inform-page ">
        <div className="flex justify-center items-center login_inform-inner">
          <div className="forgotpass-form  form_upto m-8 loginDiv-page">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              <div className="mb-1 text-center">
                <div className="text-center ChatPro-login-heading ">
                  <img
                    className="m-auto"
                    src={logo}
                    alt="logo"
                    width={"100px"}
                  />
                  <h4>
                    {" "}
                    Forgot your password? No problem. Just let us know your
                    email address and we will email you a password reset link
                    that will allow you to choose a new one.
                  </h4>
                </div>
                <form onSubmit={handleSubmit} className="animated-form">
                  <div className="mb-4 form-group">
                    <input
                      type="email"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="Email-label">
                      Email
                    </label>
                    {error && <p className="text-red-500">{error}</p>}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="login-form-btn bg-[#000] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "EMAIL PASSWORD RESET OTP"}
                    </button>
                  </div>
                </form>
              </div>
              {/* Display error message */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="flex justify-center items-center h-screen">
    //   <div className="w-full max-w-md">
    //     <div className="bg-white rounded-lg p-8 shadow-md forgotPassdiv">
    //       <div className="mb-8 text-center">
    //         <img src={logo} alt="Logo" className="mx-auto w-32" />
    //       </div>
    //       <p className="text-center text-gray-700 mb-6">
    //         Forgot your password? No problem. Just let us know your email
    //         address and we will email you a password reset link that will allow
    //         you to choose a new one.
    //       </p>
    //       <form onSubmit={handleSubmit}>
    //         <div className="mb-4">
    //           <input
    //             type="email"
    //             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
    //             placeholder="name@example.com"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //           />
    //           {error && <p className="text-red-500">{error}</p>}
    //         </div>
    //         <div className="text-center">
    //           <button
    //             type="submit"
    //             className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
    //             disabled={loading}
    //           >
    //             {loading ? "Sending..." : "EMAIL PASSWORD RESET OTP"}
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Login;
