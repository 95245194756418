import React, { useState, useEffect, useRef } from "react";
import api from "../../Api.js"; // Ensure this API has updateBlog and getBlogById methods
import { ToastContainer, toast } from "react-toastify";
import Select2 from "react-select2-wrapper";
import "react-toastify/dist/ReactToastify.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom"; // Assuming you're using react-router
import { getUserData } from "../../helpers/utils";
import DefaultImage from "../../image/DefaultImage.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const EditBlog = () => {
  const { id } = useParams();
  // Get the blog ID from URL
  const history = useHistory();
  const User = getUserData();
  const [showInput, setShowInput] = useState(false); // State to control visibility of slug input
  const inputRef = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    html: "",
    featured_image: null,
    user_id: "", // Assuming this is a string or ID fetched from somewhere
    status: "", // Default to 'draft'
    meta: {
      meta_description: "",
      meta_title: "",
      meta_keywords: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [existingImage, setExistingImage] = useState(null); // For handling existing image

  // Fetch blog data by ID when component mounts
  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const res = await api.getBlogbyId(id);
        if (res.status == 200) {
          const data = res.blog;
          setFormData({
            title: data.title,
            slug: data.slug,
            html: data.html,
            featured_image: data?.featured_image, // Will handle separately
            user_id: data.user_id,
            status: data.status,
            meta: data.meta || {
              meta_description: "",
              meta_title: "",
              meta_keywords: "",
            },
          });
          setExistingImage(data.featured_image);
          setLoading(false);
        } else {
          setLoading(false);
        }
        // Assuming getBlogById is an API method
      } catch (err) {
        console.error("Error fetching blog data:", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);
  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }], // Font and size dropdowns
      ["bold", "italic", "underline", "strike"], // Bold, italic, underline, strikethrough
      [{ script: "sub" }, { script: "super" }], // Subscript, superscript
      [{ align: [] }, { list: "ordered" }, { list: "bullet" }], // Align, ordered list, bullet list
      ["blockquote", "code-block"], // Blockquote, code block
      [{ indent: "-1" }, { indent: "+1" }], // Indent/outdent
      [{ direction: "rtl" }], // Text direction (right to left)
      ["link", "image", "video"], // Insert link, image, video
      ["clean"], // Clear formatting
      [{ table: [] }], // Insert table (table module is separate)
    ],
  };
  const handleChange = (e) => {
    if (typeof e === "string") {
      // This is the case for ReactQuill
      setFormData({
        ...formData,
        html: e, // Update the HTML content field
      });
    } else {
      const { name, value } = e.target;
      if (name.startsWith("meta")) {
        setFormData({
          ...formData,
          meta: {
            ...formData.meta,
            [name]: value, // Update the specific meta field
          },
        });
      } else {
        setFormData({
          ...formData,
          [name]: value, // Update non-meta fields
        });
      }

      if (name === "title") {
        handleChangeSlug(value); // Update slug when title changes
      }
    }
  };
  const handleChangeSlug = (title) => {
    if (typeof title !== "string") {
      console.error("Title is not a string:", title);
      return;
    }

    const slug = title
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    setFormData((prevData) => ({
      ...prevData,
      slug,
    }));
  };

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        BASE_URL_PLATFORM + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevData) => ({
        ...prevData,
        featured_image: response.data.imagePath,
      }));
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };
  const handleStatusChange = (e) => {
    const selectedValue = e.target.value; // Get the selected value
    setFormData({
      ...formData,
      status: selectedValue, // Update status in formData
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.user_id = User?._id;

    try {
      const response = await api.editBlog(id, formData); // Assuming updateBlog is an API method
      if (response.status === 200) {
        toast.success("Blog Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setTimeout(() => {
          // history.push("../blogs");
          window.location.reload();
        }, 2000);
        // Redirect to blogs page
      } else {
        console.error("Error updating blog:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating blog:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const status = [
    { id: "draft", text: "Draft" },
    { id: "published", text: "Published" },
    { id: "archived", text: "Archived" },
  ];

  useEffect(() => {
    // Detect clicks outside the input field
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false); // Hide the input and show the slug link
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, setShowInput]);

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white shadow-md rounded-lg">
      <h2
        className="text-3xl font-bold mb-6 flex justify-center item-center"
        style={{ color: "#000" }}
      >
        Edit Blog Post
      </h2>

      {/* Title Input */}
      <div className="grid lg:grid-cols-2 lg:gap-x-5">
        <div className="animated-form profileheadlinediv">
          <div className="form-group">
            <input
              type="text"
              name="title"
              placeholder=""
              value={formData.title}
              onChange={(e) => {
                handleChange(e);
              }}
              className="border rounded w-full py-2 px-3 text-gray-700"
              id="title"
            />
            <label htmlFor="title" className="profileheadline">
              Title
            </label>
          </div>
        </div>

        <div className="animated-form profileheadlinediv">
          {showInput || !formData.slug ? (
            <div ref={inputRef} className="form-group">
              <input
                type="text"
                name="slug"
                placeholder=""
                value={formData.slug}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="slug"
              />
              <label htmlFor="slug" className="profileheadline">
                Slug
              </label>
            </div>
          ) : (
            <div className="flex justify-between">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  className="chp-slug-blog-link"
                  href={`https://ChatPro.co.uk/blogs/${formData.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`https://ChatPro.co.uk/blogs/${formData.slug}`}
                </a>
                <FeatherIcon
                  icon="edit-2"
                  className="edit-icon"
                  width={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowInput(true)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* HTML Content */}
      <div className="animated-form profileheadlinediv">
        <div className="form-group">
          <ReactQuill
            value={formData.html}
            modules={modules}
            onChange={handleChange}
            className=" rounded w-full py-2 px-3 text-gray-700"
            id="html"
            theme="snow"
          />
          {/* <label htmlFor="html" className="profileheadline description-label">
            Description
          </label> */}
        </div>
      </div>

      {/* Status Dropdown */}
      <div className="grid lg:grid-cols-2 lg:gap-x-5">
        <div className="animated-form mt-3">
          <div
            className="relative job-search-label-section singleselect2 statusenroll"
            style={{ width: "100%", textAlign: "center" }}
          >
            <Select2
              data={status}
              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
              defaultValue={formData?.status}
              onChange={(e) => handleStatusChange(e)} //
              options={{
                placeholder: "Select Status",
                width: "100%",
                theme: "classic",
              }}
            />
          </div>
        </div>

        {/* Feature Image Upload */}
        <div>
          <div
            className="flex m-auto chp-upload-image"
            onChange={(e) => handleUpload(e.target.files[0])}
          >
            <div className="flex justify-center w-full">
              {formData.featured_image ? (
                <div className="chp-upload-image-border">
                  <img
                    className="DefaultImage-logo"
                    src={
                      BASE_URL_PLATFORM + "/uploads/" + formData.featured_image
                    }
                    alt="Uploaded"
                  />
                </div>
              ) : existingImage ? (
                <div>
                  <img
                    src={BASE_URL_PLATFORM + "/uploads/" + existingImage}
                    className=""
                    alt="Existing"
                  />
                </div>
              ) : (
                <div className="">
                  <img src={DefaultImage} className="w-full" alt="Default" />
                </div>
              )}
            </div>
            <label htmlFor="featured_image" className=" chp-upload-image-label">
              Feature Image
              <input
                type="file"
                accept="image/*"
                id="featured_image"
                name="featured_image"
                className="mt-2 w-full border rounded-lg p-2 profileheadline"
              />
            </label>
          </div>
          <div
            className="flex justify-center py-3  font-semibold text-sm "
            style={{ color: "#000" }}
          >
            Featured Image
          </div>
        </div>
      </div>

      {/* Meta Information */}
      <div className="metadetails">
        <h2
          className="text-xl font-semibold mt-2 underline"
          style={{ color: "#000" }}
        >
          Meta Information
        </h2>
        <div className="grid lg:grid-cols-2 lg:gap-x-5">
          <div className="animated-form profileheadlinediv">
            <div className="form-group">
              <input
                type="text"
                name="meta_title"
                placeholder=""
                value={formData.meta?.meta_title}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="meta_title"
              />
              <label htmlFor="meta_title" className="profileheadline">
                Meta Title
              </label>
            </div>
          </div>
          <div className="animated-form profileheadlinediv">
            <div className="form-group">
              <input
                type="text"
                name="meta_keywords"
                placeholder=""
                value={formData.meta?.meta_keywords}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="meta_keywords"
              />
              <label htmlFor="meta_keywords" className="profileheadline">
                Meta Keyword
              </label>
            </div>
          </div>
        </div>
        <div className="animated-form profileheadlinediv">
          <div className="form-group">
            <textarea
              name="meta_description"
              placeholder=""
              value={formData.meta?.meta_description}
              onChange={handleChange}
              className="border rounded  w-full py-2 px-3 text-gray-700"
              id="meta_description"
              rows={7}
            ></textarea>
            <label
              htmlFor="meta_description"
              className="profileheadline description-label"
            >
              Meta Description
            </label>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-between gap-3 mt-5">
        <button
          type="submit"
          className="chp-find-course-btn px-8 py-2 rounded-full"
        >
          Update Blog
        </button>
      </div>

      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <ToastContainer />
    </form>
  );
};

export default EditBlog;
