import React from "react";
const TermAndCondition = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen py-10 bg-gray-100">
        <div className="bg-white shadow-lg rounded-md p-8 max-w-5xl mx-4">
          <h1 className="text-3xl font-bold mb-4 " style={{ color: "#000" }}>
            Privacy Policy
          </h1>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Introduction
            </h3>
            <p className="text-gray-700">
              ChatPro is committed to protecting your privacy. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you use our website.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Information We Collect
            </h3>
            <p className="text-gray-700">
              We may collect personal information such as your name, email
              address, phone number, resume, and employment history when you
              create an account, post a job, or apply for a job.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              How We Use Your Information
            </h3>
            <p className="text-gray-700">
              We use your information to provide and improve our services,
              communicate with you, process your job applications, and for other
              legitimate business purposes.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Sharing Your Information
            </h3>
            <p className="text-gray-700">
              We may share your information with employers when you apply for a
              job, with service providers who assist us in operating our
              website, and as required by law.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Security
            </h3>
            <p className="text-gray-700">
              We implement reasonable security measures to protect your
              information from unauthorized access, use, or disclosure.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Your Choices
            </h3>
            <p className="text-gray-700">
              You can access, update, or delete your personal information by
              logging into your account. You may also contact us to request
              these changes.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Changes to this Privacy Policy
            </h3>
            <p className="text-gray-700">
              We may update this Privacy Policy from time to time. Your
              continued use of ChatPro after any changes indicates your
              acceptance of the new Privacy Policy.
            </p>
          </div>

          <div>
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Contact Us
            </h3>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <a href="mailto:info@ChatPro.com">info@ChatPro.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermAndCondition;
