import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client"; // Import Socket.IO client
import EmojiPicker from "emoji-picker-react";
import { getUserData } from "../../helpers/utils";
import chaticon from "../../image/defaultchat.png";
// Replace with your server URL
const socket = io("http://89.117.144.11:5001", {
  transports: ["websocket"], // Force WebSocket transport
});

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const WhatsAppChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const User = getUserData();
  // New state variables for customization
  const [defaultChatMessage, setDefaultChatMessage] = useState("");
  const [headerText, setHeaderText] = useState("Chat");
  const [headerBgColor, setHeaderBgColor] = useState("#ff6800");
  const [headerBgValue, setHeaderBgValue] = useState("#ff6800");
  const [subHeaderBgValue, setSubHeaderBgValue] = useState("#fff");
  const [subheaderBgColor, setSubHeaderBgColor] = useState("#fff");
  const [headerTextValue, setHeaderTextValue] = useState("#ffffff");
  const [headerTextColor, setHeaderTextColor] = useState("#FFFFFF");
  const [footerBgColor, setFooterBgColor] = useState("#ff6800");
  const [footerTextColor, setFooterTextColor] = useState("#ff6800");
  const [footerBgValue, setFooterBgValue] = useState("#ff6800");
  const [footerTextValue, setFooterTextValue] = useState("#ff6800");
  const [chatBgValue, setChatBgValue] = useState("#ffffff");
  const [sendBtnBgColor, setSendBtnBgColor] = useState("#271360");
  const [sendBtnBgValue, setSendBtnBgValue] = useState("#271360");
  const [sendBtnTextColor, setSendBtnTextColor] = useState("#FFFFFF");
  const [sendBtnTextValue, setSendBtnTextValue] = useState("#ffffff");
  const [chatBgColor, setChatBgColor] = useState("#FFFFFF");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [SubHeaderText, setSubHeaderText] = useState("Sub Title");

  const [btnbgColor, setBtnbgColor] = useState("#ff6800");
  const [iconbtnbgColorValue, setIconbtnbgColorValue] = useState("#ff6800");
  const [image, setImage] = useState(chaticon);

  // Initial font sizes
  const [headerFontSize, setHeaderfontSize] = useState(24);
  const [subHeaderFontSize, setSubheaderfontSize] = useState(18);

  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Listen for incoming messages
    socket.on("receiveMessage", (message) => {
      setLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: prevMessages.length + 1, ...message },
      ]);
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("receiveMessage");
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    scrollToBottom();
  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();
    const userId = User?._id;
    if (input.trim()) {
      const newMessage = {
        id: messages.length + 1,
        text: input,
        sender: "user",
        userId,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      socket.emit("sendMessage", { userId, message: input, prompt: input });
      setLoading(true);
      setInput(""); // Clear input field after sending
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setInput((prevInput) => prevInput + emojiObject.emoji);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`${domainpath}/api/settings/${User?._id}`);

        const result = await response.json();
        if (result.success) {
          const settings = result.data.settings;
          setHeaderText(settings.headerText || "Chat");
          setHeaderBgColor(settings.headerBgColor || "#ff6800");
          setSubHeaderBgValue(settings.subHeaderBgValue || "#fff");
          setSubHeaderBgColor(settings.subheaderBgColor || "#fff");
          setHeaderTextValue(settings.headerTextValue || "#ffffff");
          setHeaderTextColor(settings.headerTextColor || "#FFFFFF");
          setFooterBgColor(settings.footerBgColor || "#ff6800");
          setFooterTextColor(settings.footerTextColor || "#ff6800");
          setFooterBgValue(settings.footerBgValue || "#ff6800");
          setChatBgValue(settings.chatBgValue || "#ffffff");
          setHeaderBgValue(settings.headerBgValue || "#ff6800");
          setFooterTextValue(settings.footerTextValue || "#ff6800");
          setSendBtnBgColor(settings.sendBtnBgColor || "#271360");
          setSendBtnBgValue(settings.sendBtnBgValue || "#271360");
          setSendBtnTextColor(settings.sendBtnTextColor || "#FFFFFF");
          setSendBtnTextValue(settings.sendBtnTextValue || "#ffffff");
          setChatBgColor(settings.chatBgColor || "#FFFFFF");
          setDefaultChatMessage(settings.defaultChatMessage || "");
          setFontFamily(settings.fontFamily || "Arial");
          setSubHeaderText(settings.SubHeaderText || "Sub Title");
          setHeaderfontSize(settings.headerFontSize || 24);
          setSubheaderfontSize(settings.subHeaderFontSize || 18);
          setIconbtnbgColorValue(settings.iconbtnbgColorValue || "#ff6800");
          setBtnbgColor(settings.btnbgColor || "#ff6800");
          setImage(settings.image || chaticon);
          // var iframeapp = document.getElementsByClassName("chat-launcher");
          // if (iframeapp.length > 0) {
          //   var existingImage = iframeapp[0].querySelector("img");

          //   if (!existingImage) {
          //     var img = document.createElement("img");

          //     img.src = settings.image || chaticon;
          //     img.alt = "Chat Launcher Image";
          //     img.style.width = "100%";
          //     img.style.height = "100%";
          //     img.style.borderRadius = "50%";

          //     iframeapp[0].appendChild(img);
          //   }
          // }
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handlePickerChange = (e, type) => {
    const value = e.target.value;
    if (type === "header") {
      setHeaderTextColor(value);
      setHeaderTextValue(value); // Update both color and text input
    } else if (type === "footer") {
      setFooterTextColor(value);
      setFooterTextValue(value); // Update both color and text input
    } else if (type === "sendBtnBg") {
      setSendBtnBgColor(value);
      setSendBtnBgValue(value);
    } else if (type === "sendBtnText") {
      setSendBtnTextColor(value);
      setSendBtnTextValue(value);
    } else if (type === "footerBgColor") {
      setFooterBgColor(value);
      setFooterBgValue(value);
    } else if (type === "chatBgColor") {
      setChatBgColor(value);
      setChatBgValue(value);
    } else if (type === "headerBgColor") {
      setHeaderBgColor(value);
      setHeaderBgValue(value);
    } else if (type === "subHeaderBgColor") {
      setSubHeaderBgColor(value);
      setSubHeaderBgValue(value); // Update both color and text input
    } else if (type === "btnbgColor") {
      setBtnbgColor(value);
      setIconbtnbgColorValue(value); // Update both color and text input
    }
  };

  // Handle changes in the text input
  const handleTextChange = (e, type) => {
    const value = e.target.value;
    if (type === "header") {
      setHeaderTextValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setHeaderTextColor(validatedColor);
      }
    } else if (type === "footer") {
      setFooterTextValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setFooterTextColor(validatedColor);
      }
    } else if (type === "sendBtnBg") {
      setSendBtnBgValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setSendBtnBgColor(validatedColor);
      }
    } else if (type === "sendBtnText") {
      setSendBtnTextValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setSendBtnTextColor(validatedColor);
      }
    } else if (type === "footerBgColor") {
      setFooterBgValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setFooterBgColor(validatedColor);
      }
    } else if (type === "chatBgColor") {
      setChatBgValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setChatBgColor(validatedColor);
      }
    } else if (type === "headerBgColor") {
      setHeaderBgValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setHeaderBgColor(validatedColor);
      }
    } else if (type === "subHeaderBgColor") {
      setSubHeaderBgValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setSubHeaderBgColor(validatedColor);
      }
    } else if (type === "btnbgColor") {
      setIconbtnbgColorValue(value);
      const validatedColor = getValidatedColor(value);
      if (validatedColor) {
        setBtnbgColor(validatedColor);
      }
    }
  };

  // Handle blur event to validate and apply color
  const handleTextBlur = (type) => {
    let validColor;
    if (type === "header") {
      validColor = getValidatedColor(headerTextValue);
      if (validColor) {
        setHeaderTextColor(validColor);
        setHeaderTextValue(validColor);
      } else {
        setHeaderTextValue(headerTextColor); // Revert to last valid color
      }
    } else if (type === "footer") {
      validColor = getValidatedColor(footerTextValue);
      if (validColor) {
        setFooterTextColor(validColor);
        setFooterTextValue(validColor);
      } else {
        setFooterTextValue(footerTextColor); // Revert to last valid color
      }
    } else if (type === "sendBtnBg") {
      validColor = getValidatedColor(sendBtnBgValue);
      if (validColor) {
        setSendBtnBgColor(validColor);
        setSendBtnBgValue(validColor);
      } else {
        setSendBtnBgValue(sendBtnBgColor); // Revert to last valid color
      }
    } else if (type === "sendBtnText") {
      validColor = getValidatedColor(sendBtnTextValue);
      if (validColor) {
        setSendBtnTextColor(validColor);
        setSendBtnTextValue(validColor);
      } else {
        setSendBtnTextValue(sendBtnTextColor); // Revert to last valid color
      }
    } else if (type === "footerBgColor") {
      validColor = getValidatedColor(footerBgValue);
      if (validColor) {
        setFooterBgColor(validColor);
        setFooterBgValue(validColor);
      } else {
        setFooterBgValue(footerBgColor);
      }
    } else if (type === "chatBgColor") {
      validColor = getValidatedColor(chatBgValue);
      if (validColor) {
        setChatBgColor(validColor);
        setChatBgValue(validColor);
      } else {
        setChatBgValue(chatBgColor);
      }
    } else if (type === "headerBgColor") {
      validColor = getValidatedColor(headerBgValue);
      if (validColor) {
        setHeaderBgColor(validColor);
        setHeaderBgValue(validColor);
      } else {
        setHeaderBgValue(headerBgColor);
      }
    } else if (type === "subHeaderBgColor") {
      validColor = getValidatedColor(subHeaderBgValue);
      if (validColor) {
        setSubHeaderBgColor(validColor);
        setSubHeaderBgValue(validColor);
      } else {
        setSubHeaderBgValue(subheaderBgColor); // Revert to last valid color
      }
    } else if (type === "btnbgColor") {
      validColor = getValidatedColor(btnbgColor);
      if (validColor) {
        setIconbtnbgColorValue(validColor);
        setIconbtnbgColorValue(validColor);
      } else {
        setIconbtnbgColorValue(btnbgColor); // Revert to last valid color
      }
    }
  };

  const getValidatedColor = (color) => {
    const s = new Option().style;
    s.color = color;
    if (s.color === "") return null; // Invalid color
    const tempDiv = document.createElement("div");
    tempDiv.style.color = color;
    document.body.appendChild(tempDiv);
    const computedColor = getComputedStyle(tempDiv).color; // Get RGB
    document.body.removeChild(tempDiv);
    return rgbToHex(computedColor);
  };

  const rgbToHex = (rgb) => {
    const result = rgb.match(/\d+/g);
    if (!result) return null;
    const [r, g, b] = result.map(Number);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Update the image state with file content
      };
      reader.readAsDataURL(file); // Read the file as base64 URL
    }
  };

  const increaseHeaderFontSize = () => {
    setHeaderfontSize((prevSize) => prevSize + 2);
  };
  const decreaseHeaderFontSize = () => {
    setHeaderfontSize((prevSize) => Math.max(prevSize - 2, 8));
  };

  const increaseSubheaderFontSize = () => {
    setSubheaderfontSize((prevSize) => prevSize + 2);
  };

  const decreaseSubheaderFontSize = () => {
    setSubheaderfontSize((prevSize) => Math.max(prevSize - 2, 8));
  };
  const handleHeaderFontSizeChange = (e) => {
    setHeaderfontSize(Number(e.target.value));
  };
  const handleSubheaderFontSizeChange = (e) => {
    setSubheaderfontSize(Number(e.target.value));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      headerText,
      headerBgColor,
      subHeaderBgValue,
      subheaderBgColor,
      headerTextValue,
      headerTextColor,
      footerBgColor,
      footerTextColor,
      defaultChatMessage,
      footerBgValue,
      chatBgValue,
      headerBgValue,
      footerTextValue,
      sendBtnBgColor,
      sendBtnBgValue,
      sendBtnTextColor,
      sendBtnTextValue,
      chatBgColor,
      btnbgColor,
      iconbtnbgColorValue,
      fontFamily,
      SubHeaderText,
      headerFontSize,
      subHeaderFontSize,
      image,
    };
    console.log("Form Data: ", formData);
    try {
      const response = await fetch(`${domainpath}/api/settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: User?._id, settings: formData }), // Replace with actual userId
      });

      const result = await response.json();
      if (result.success) {
        alert("Settings saved successfully!");
      } else {
        alert("Error saving settings: " + result.message);
      }
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const initializeDefaultMessage = () => {
    if (defaultChatMessage && messages.length === 0) {
      setMessages([
        {
          id: 1,
          content: defaultChatMessage,
          sender: "assistant",
        },
      ]);
    }
  };

  useEffect(() => {
    initializeDefaultMessage(); // Initialize default chat message on component mount
  }, [defaultChatMessage]);
  return (
    <div>
      <div className="md:flex justify-between lg:px-0 md:px-10 mx-5">
        <div className="lg:w-96 w-64">
          <div className="section-height">
            {isOpen && (
              <div
                className="bottom-16 right-4 flex flex-col chat-custom-style shadow-lg rounded-lg md:w-80"
                style={{ backgroundColor: chatBgColor, fontFamily: fontFamily }}
              >
                {/* Header */}
                <div
                  className="flex "
                  style={{ backgroundColor: headerBgColor }}
                >
                  <div className="p-4 rounded-t-lg">
                    {" "}
                    <div>
                      <h1
                        className="text-lg font-bold  "
                        style={{ color: headerTextColor }}
                      >
                        <div style={{ fontSize: `${headerFontSize}px` }}>
                          <p>{headerText}</p>
                        </div>
                      </h1>
                    </div>
                    <div>
                      {" "}
                      <h1
                        className="text-sm font-bold capitalize "
                        style={{ color: subheaderBgColor }}
                      >
                        <div style={{ fontSize: `${subHeaderFontSize}px` }}>
                          <h2>{SubHeaderText}</h2>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>

                {/* Messages Section */}
                <div
                  className="flex-1 p-4 overflow-y-auto custom-scrollbar"
                  ref={scrollRef}
                >
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`flex ${
                        message.sender === "user"
                          ? "justify-end"
                          : "justify-start"
                      } my-4`}
                    >
                      {message.content ? (
                        <div
                          className={`p-2 rounded-lg ${
                            message.sender === "user"
                              ? "sender-chat bg-white"
                              : "received-chat"
                          }`}
                        >
                          {message.content}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                  {loading && (
                    <div className="flex justify-start my-4">
                      <div className="chatgptloader"></div>
                    </div>
                  )}
                </div>

                <div>
                  {/* Footer / Message Input Section */}
                  <form
                    onSubmit={sendMessage}
                    className="flex p-2 border-t border-gray-300"
                    style={{ backgroundColor: footerBgColor }}
                  >
                    <div className="relative flex-1">
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        className="flex-1 p-2 border rounded-lg w-full focus:outline-none"
                        placeholder="Type a message..."
                      />
                      <button
                        type="button"
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                      >
                        😊
                      </button>
                      {showEmojiPicker && (
                        <div className="absolute bottom-14 z-10 custom-chat-palnel-emoje">
                          <EmojiPicker
                            height={350}
                            width={380}
                            onEmojiClick={handleEmojiClick}
                          />
                        </div>
                      )}
                    </div>

                    {/* Send Button */}
                    <button
                      type="submit"
                      style={{
                        backgroundColor: sendBtnBgColor,
                        color: sendBtnTextColor,
                      }}
                      className="ml-2 rounded-lg px-4 py-2"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end py-5">
            <div
              className="text-white rounded-full flex p-1 cursor-pointer shadow-lg w-16 h-16"
              style={{
                backgroundColor: btnbgColor,
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* Display the uploaded image if available, else show "+" */}
              {image ? (
                <img
                  src={image}
                  alt="Uploaded Icon"
                  className="rounded-full "
                  onError={(e) => setImage(chaticon)}
                />
              ) : (
                <div className="text-3xl m-auto">
                  <img
                    src={chaticon}
                    alt="Uploaded Icon"
                    className="rounded-full "
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Settings Section for Customization */}
        <form
          onSubmit={handleSubmit}
          style={{
            boxshadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <div className="p-4 bg-gray-100 shadow-md border-r lg:w-96 w-60">
            <div className="flex justify-between items-center">
              {" "}
              <h2 className="text-xl font-bold  text-gray-800">Settings</h2>
              <div className="hover-show-main relative m-2 shadow-md cursor-pointer">
                {/* Input for file upload */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload} // Handles the file upload
                  className="absolute top-0 p-4 w-full opacity-0 cursor-pointer"
                />

                {/* Image display with fallback */}
                <div className="px-4 py-3 bg-white border border-gray-300 rounded cursor-pointer">
                  {image ? (
                    <img
                      src={image}
                      alt="Chat Icon"
                      className="w-12 h-12"
                      onError={(e) => setImage(chaticon)} // Fallback to default image on error
                    />
                  ) : (
                    <img
                      src={chaticon}
                      alt="Default Icon"
                      className=" w-12 h-12"
                    />
                  )}
                </div>

                {/* Tooltip for hover effect */}
                <div className="hover-show">change icon</div>
              </div>
            </div>
            <div className="ana-custom-scroll">
              {/* Header Text Changer */}
              <div className="mb-6 bg-white p-3 shadow ">
                <div>
                  <div className=" justify-between">
                    <label className="  block  mb-2 font-medium text-black text-sm">
                      Header title
                    </label>{" "}
                    <div className="py-2">
                      <div className="text-center justify-end  gap-2 flex  pb-2">
                        <button
                          onClick={decreaseHeaderFontSize}
                          className="px-2 py-1 font-semibold rounded-lg shadow-md   transition-all duration-200"
                        >
                          <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </button>
                        <input
                          type="number"
                          id="header-font-size"
                          value={headerFontSize}
                          onChange={handleHeaderFontSizeChange}
                          className=" px-4 w-20 py-2 border h-5 border-gray-300 rounded-md"
                          min="8"
                        />
                        <button
                          onClick={increaseHeaderFontSize}
                          className="px-2 py-1 font-semibold rounded-lg shadow-md transition-all duration-200"
                        >
                          <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                          </svg>
                        </button>
                      </div>
                      <div className="  flex justify-end ">
                        <label className="w-1/3 block  font-medium text-black text-xs">
                          Header Color
                        </label>
                        {/* Color Picker */}
                        <div className="  flex justify-end gap-2">
                          {" "}
                          <input
                            type="color"
                            value={headerTextColor}
                            onChange={(e) => handlePickerChange(e, "header")}
                            className="h-6 cursor-pointer border border-gray-300 rounded-lg"
                          />
                          {/* Text Input */}
                          <input
                            type="text"
                            value={headerTextValue}
                            onChange={(e) => handleTextChange(e, "header")}
                            onBlur={() => handleTextBlur("header")}
                            // Validate on blur
                            className="h-6 w-1/3 border border-gray-300 rounded-lg px-1 text-center"
                            placeholder="Enter hex or color name"
                          />
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={headerText}
                    onChange={(e) => setHeaderText(e.target.value)}
                    className=" w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Enter Header Text..."
                  />
                </div>

                <div>
                  <label className="  block  mt-4 mb-2 font-medium text-black text-sm">
                    Sub Header title
                  </label>
                  <div className="py-2">
                    <div className="text-center justify-end  gap-2 flex  pb-2">
                      <div className="text-center gap-3 flex justify-around">
                        <button
                          onClick={decreaseSubheaderFontSize}
                          className="px-2 py-1 font-semibold rounded-lg shadow-md   transition-all duration-200"
                        >
                          <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                          </svg>
                        </button>
                        <input
                          type="number"
                          id="subheader-font-size"
                          value={subHeaderFontSize}
                          onChange={handleSubheaderFontSizeChange}
                          className=" h-5 w-16 py-1 px-2 border border-gray-300 rounded-md"
                          min="8"
                        />
                        <button
                          onClick={increaseSubheaderFontSize}
                          className="px-2 py-1 font-semibold rounded-lg shadow-md transition-all duration-200"
                        >
                          <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mb-6">
                      <label className="block font-medium mb-2">
                        Default Chat Message
                      </label>
                      <input
                        type="text"
                        value={defaultChatMessage}
                        onChange={(e) => setDefaultChatMessage(e.target.value)}
                        className="w-full p-2 border rounded-lg"
                        placeholder="Enter a default chat message..."
                      />
                    </div>
                    <div className="  flex justify-end ">
                      <label className="w-1/3 block  font-medium text-black text-xs">
                        Sub Title Color
                      </label>
                      <div className="  flex justify-end gap-2">
                        {" "}
                        <input
                          type="color"
                          id="subHeaderBgColor"
                          value={subheaderBgColor}
                          onChange={(e) =>
                            handlePickerChange(e, "subHeaderBgColor")
                          }
                          className="h-6 cursor-pointer border border-gray-300 rounded-lg"
                        />
                        {/* Text Input for Manual Color Entry */}
                        <input
                          type="text"
                          value={subHeaderBgValue}
                          onChange={(e) =>
                            handleTextChange(e, "subHeaderBgColor")
                          }
                          onBlur={() => handleTextBlur("subHeaderBgColor")}
                          className="h-6 w-1/3 border border-gray-300 rounded-lg px-1 text-center"
                          placeholder="Enter hex or color name"
                        />
                      </div>
                    </div>
                  </div>

                  <input
                    type="text"
                    value={SubHeaderText}
                    onChange={(e) => setSubHeaderText(e.target.value)}
                    className=" w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Enter Header Text..."
                  />
                </div>
              </div>
              {/* Header Background Color Selector */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="  w-1/2 block  font-medium text-black text-xs	">
                  Header Background Color
                </label>
                <div className=" w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    value={headerBgColor}
                    onChange={(e) => handlePickerChange(e, "headerBgColor")}
                    className="h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={headerBgValue}
                    onChange={(e) => handleTextChange(e, "headerBgColor")}
                    onBlur={() => handleTextBlur("headerBgColor")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>

              {/*  sub Header Text Color Selector */}

              {/* Chat Section Background Color */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="w-1/2 block  font-medium text-black text-xs">
                  Chat Background Color
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    value={chatBgColor}
                    onChange={(e) => handlePickerChange(e, "chatBgColor")}
                    className="h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={chatBgValue}
                    onChange={(e) => handleTextChange(e, "chatBgColor")}
                    onBlur={() => handleTextBlur("chatBgColor")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
              {/* Font Family Selector */}
              <div className="mb-6 bg-white p-3 shadow">
                <label className="block mb-2 font-medium text-gray-600 text-sm">
                  Font Family
                </label>
                <select
                  value={fontFamily}
                  onChange={(e) => setFontFamily(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  <option value="Arial">Arial</option>
                  <option value="Helvetica">Helvetica</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Impact">Impact</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Comic Sans MS">Comic Sans MS</option>
                  <option value="Lucida Console">Lucida Console</option>
                  <option value="Lucida Sans Unicode">
                    Lucida Sans Unicode
                  </option>
                  <option value="Palatino Linotype">Palatino Linotype</option>
                  <option value="Garamond">Garamond</option>
                  <option value="Bookman">Bookman</option>
                  <option value="Candara">Candara</option>
                  <option value="Century Gothic">Century Gothic</option>
                  <option value="Franklin Gothic Medium">
                    Franklin Gothic Medium
                  </option>
                  <option value="Gill Sans">Gill Sans</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Futura">Futura</option>
                  <option value="Geneva">Geneva</option>
                  <option value="Optima">Optima</option>
                  <option value="Perpetua">Perpetua</option>
                  <option value="Brush Script MT">Brush Script MT</option>
                  <option value="Poppins">Poppins</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Lato">Lato</option>
                  <option value="Oswald">Oswald</option>
                  <option value="Nunito">Nunito</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Raleway">Raleway</option>
                  <option value="Ubuntu">Ubuntu</option>
                  <option value="Open Sans">Open Sans</option>
                  <option value="Playfair Display">Playfair Display</option>
                  <option value="PT Serif">PT Serif</option>
                  <option value="Dancing Script">Dancing Script</option>
                  <option value="Caveat">Caveat</option>
                  <option value="Merriweather">Merriweather</option>
                  <option value="Quicksand">Quicksand</option>
                  <option value="Overpass">Overpass</option>
                </select>
              </div>
              {/* Background Color Changer for Footer */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label
                  htmlFor="footerBgColor"
                  className="w-1/2 block  font-medium text-black text-xs"
                >
                  Footer Background Color:
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    id="footerBgColor"
                    value={footerBgColor}
                    onChange={(e) => handlePickerChange(e, "footerBgColor")}
                    className="h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={footerBgValue}
                    onChange={(e) => handleTextChange(e, "footerBgColor")}
                    onBlur={() => handleTextBlur("footerBgColor")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
              {/* Footer Text Color Selector */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="w-1/2 block  font-medium text-black text-xs">
                  Footer Text Color
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  {" "}
                  <input
                    type="color"
                    value={footerTextColor}
                    onChange={(e) => handlePickerChange(e, "footer")}
                    className=" h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={footerTextValue}
                    onChange={(e) => handleTextChange(e, "footer")}
                    onBlur={() => handleTextBlur("footer")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
              {/* Send Button Background Color */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="w-1/2 block  font-medium text-black text-xs ">
                  Send Button Background Color
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    value={sendBtnBgColor}
                    onChange={(e) => handlePickerChange(e, "sendBtnBg")}
                    className=" h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={sendBtnBgValue}
                    onChange={(e) => handleTextChange(e, "sendBtnBg")}
                    onBlur={() => handleTextBlur("sendBtnBg")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
              {/* Send Button Text Color */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="w-1/2 block  font-medium text-black text-xs">
                  Send Button Text Color
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    value={sendBtnTextColor}
                    onChange={(e) => handlePickerChange(e, "sendBtnText")}
                    className=" h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={sendBtnTextValue}
                    onChange={(e) => handleTextChange(e, "sendBtnText")}
                    onBlur={() => handleTextBlur("sendBtnText")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
              {/* Send Button Text Color */}
              <div className="mb-6 flex justify-between border raunded p-3 shadow bg-white items-center">
                <label className="w-1/2 block  font-medium text-black text-xs">
                  Icon btn background color
                </label>
                <div className="w-1/2 flex justify-end gap-2">
                  <input
                    type="color"
                    value={btnbgColor}
                    onChange={(e) => handlePickerChange(e, "btnbgColor")}
                    className=" h-10 cursor-pointer border border-gray-300 rounded-lg"
                  />
                  <input
                    type="text"
                    value={iconbtnbgColorValue}
                    onChange={(e) => handleTextChange(e, "btnbgColor")}
                    onBlur={() => handleTextBlur("btnbgColor")}
                    className="h-5 w-1/2 border border-gray-300 rounded-lg px-1 py-5 text-center"
                    placeholder="Enter hex or color name"
                  />
                </div>
              </div>
            </div>
            <div className="pt-3">
              <button
                type="submit"
                className="px-6 py-2 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 transition-all duration-200"
                style={{ backgroundColor: "#ff6800" }}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WhatsAppChat;
