// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import { Header } from "./Header";
import Dashboard from "../SuperAdmin/Dasboard";
import { SuperAdmin } from "./dashboardmenus";
import { PopupProvider } from "../Common/PopupContext";
import Messages from "../SuperAdmin/Messages";
import ChatPrompt from "../SuperAdmin/Chatbotpanel.js";
import Integrate from "../SuperAdmin/Integrate.js";
import Leads from "../SuperAdmin/Leads.js";
import Blogs from "../SuperAdmin/Blogs.js";
import AddBlog from "../SuperAdmin/AddBlog.js";
import EditBlog from "../SuperAdmin/EditBlog.js";
import Tickets from "../SuperAdmin/Ticket.js";
import AddTickets from "../SuperAdmin/Addticket.js";
import EditTicket from "../SuperAdmin/EditTicket.js";
import Customchatsetting from "../SuperAdmin/Customchatsetting.js";

function SuperAdminLayout({ userRole }) {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");
  console.log(user);

  useEffect(() => {}, [user]);
  let { path } = useRouteMatch(); // Get the base path for nested routes

  return (
    <PopupProvider>
      <div className="">
        <Header />
        <div className=" container m-auto chp-min-h-screen ">
          {user ? <SuperAdmin /> : ""}
          <section className="mt-5">
            <div className="bg-white container JobCategories-heading-admin mb-20 m-auto">
              <Switch>
                <Route exact path={path} component={Dashboard} />
                <Route path={`${path}/leads`} component={Leads} />
                <Route path={`${path}/blogs`} component={Blogs} />
                <Route path={`${path}/add-blog`} component={AddBlog} />
                <Route path={`${path}/edit-blog/:id`} component={EditBlog} />
                <Route path={`${path}/messages`} component={Messages} />
                <Route path={`${path}/chat`} component={ChatPrompt} />
                <Route path={`${path}/integrate`} component={Integrate} />
                <Route
                  path={`${path}/custom-setting`}
                  component={Customchatsetting}
                />
                <Route path={`${path}/tickets`} component={Tickets} />
                <Route path={`${path}/add-ticket`} component={AddTickets} />
                <Route
                  path={`${path}/edit-ticket/:id`}
                  component={EditTicket}
                />

                {/* <Route
                  path={`${path}/account-settings`}
                  component={AccountSettings}
                /> */}
                {/* Add other routes as needed */}
              </Switch>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default SuperAdminLayout;
