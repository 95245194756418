import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "../../css/animations.css";
import "slick-carousel/slick/slick-theme.css";
import Qrcode from "../../homepageimage/Qrcode.png";
import computer from "../../homepageimage/computer.png";
import frist_section from "../../homepageimage/image554.png";
import two_section from "../../homepageimage/image_154454.png";
import computer_res from "../../homepageimage/new_4545.png";
import crousel_12 from "../../homepageimage/crousel_12.png";
import image_slider from "../../homepageimage/slider_image_2.png";
import image_slider_3 from "../../homepageimage/slider_image_3.png";
import image_slider_4 from "../../homepageimage/slider_image_4.png";
import Emailpage from "../Website/emailsection.js";
import Whatsapp from "../Website/Chatpanel.js";
function Page() {
  const [Values, setValues] = useState(true);
  const [Vision, setVision] = useState(false);
  const [Mission, setMission] = useState(false);

  const imagedata = [
    {
      img: crousel_12,
    },

    {
      img: image_slider,
    },
    {
      img: image_slider_3,
    },
    {
      img: image_slider_4,
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const cardSlide = [
    {
      img: crousel_12,
    },

    {
      img: image_slider,
    },
    {
      img: image_slider_3,
    },
    {
      img: image_slider_4,
    },
  ];
  var cardsetting = {
    dots: false,
    // infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        slidesToShow: 1,
      },
    ],
  };

  return (
    <>
      <div className="container-fluid  m-auto zoom_in_out">
        <div className="w-full ">
          <section className="hero  bg-gradient-to-b">
            <div className="bg_one from-[#D7E4E4]  ">
              <div className="relative computer_4454 ">
                <div className="lg:flex lg:w-4/5 md:w-4/5  m-auto main-sectoin-padding md:pt-48 pt-7 md:justify-around	container mx-auto ">
                  <div className="lg:w-3/5 md:w-9/12 md:px-0 px-5 xl:w-3/5 computer_443424 Your_Problem">
                    <div className="font_boost_Business ml-0  ">
                      <h1 className="z-30  font_boost_new font_Boost_famliy leading-none lg:text-7xl  md:text-5xl ">
                        Boost Your
                      </h1>
                      <h1 className=" font_boost_new font_boost_new_famliy leading-none ">
                        Business
                      </h1>
                      <p className="lg:text-xl  md:text-xs Resolves_Your text-white font-family: 'Poppins', sans-serif; font-medium	pt-5">
                        ChatAI Is The Solution Master, Resolves Your Problem
                        Faster.
                      </p>
                      <div className="flex sm:gap-4 text-center  lg:mx-0  lg:pt-10 md:pt-4  xl:pt-5 ">
                        <div>
                          <div className="lg:h-2 md:h-3 xl:h-8 z-20 "></div>
                          <div>
                            <img
                              className="rounded-xl md:h-24 xl:h-36 xl:w-36  z-20 "
                              src={Qrcode}
                              alt="arrow"
                              width=""
                              height=""
                            />
                          </div>
                        </div>
                        <div className="md:w-8/12 xl:w-3/4 z-20  ">
                          <img
                            src={computer}
                            alt="arrow"
                            width=""
                            height=""
                            className="md:hidden lg:block computer_dasktop"
                          />
                          <img
                            src={computer_res}
                            alt="arrow"
                            width=""
                            height=""
                            className="lg:hidden md:w-[300px]  "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-2/5 md:w-full xl:w-1/3 phone_image_212 z-10 relative	">
                    <div className="phoneContent">
                      <div className="ripple-1"></div>
                      <div className="ripple-2"></div>
                      <div className="ripple-3"></div>
                      <div className="ripple-4"></div>
                      <div className="ripple-5"></div>
                      <div className="volume style-2"></div>
                      <div className="switch style-2"></div>
                      <div className="phoneWrapper">
                        <div className="in">
                          <iframe
                            className="getFrame"
                            src="https://up2client.com/envato/amigo-chatgpt-pwa/main-file/index.html"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lines">
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
              <div className="line-container">
                <div className="animated-line"></div>
              </div>
            </div>
          </section>
          <section className="sec3  relative animate__animated animate__backInUp bg-white w-4/5 m-auto">
            <div className=" ">
              <h1
                className="  font-bold	lg:text-6xl 	"
                style={{ color: "#00023F" }}
              >
                How It Work
              </h1>
            </div>
            <div className="flex justify-center">
              <div className="w-1/2 text-[#FF6800] font-semibold text-start">
                <div className="flex justify-end">
                  <img src={frist_section} height="" width="" />
                </div>
              </div>
              <div className="border-black"></div>
              <div
                className="w-1/2 font-semibold text-start"
                style={{ color: "#FF6800" }}
              >
                <div>
                  <img src={two_section} height="" width="" />
                </div>
              </div>
            </div>
          </section>
          <div className=" Rectangle376background ">
            <section className=" container mx-auto ">
              <div
                className="flex lg:text-6xl font-bold  md:text-5xl  justify-center w-3/4 m-auto mt-10"
                style={{ color: "#00023F" }}
              >
                <h1>MOBLE APP SCREENS</h1>
              </div>
              <div className="w-3/4 m-auto pt-10">
                <div className="slide-container">
                  <Slide {...settings}>
                    {imagedata.map((item) => (
                      <div className="px-18 mx-4 flex justify-center">
                        <img
                          src={item.img}
                          height="100%"
                          width=""
                          className="mx-5"
                        />
                      </div>
                    ))}
                  </Slide>
                </div>
              </div>
            </section>
            <section className="  mt-10">
              <div className="container m-auto ">
                <div className="lg:w-full w-1/2 m-auto py-5">
                  <div className="lg:w-full text-center drop-shadow-md ">
                    <p className="  lg:text-6xl font-bold text-white uppercase md:text-[45px]">
                      about us
                    </p>
                    <div className="flex justify-center mt-10">
                      <video width="800" height="450" controls>
                        <source src="movie.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Emailpage />
        </div>
      </div>
    </>
  );
}
export default Page;
