import React, { useState } from "react";
import frist_input from "../../chatbot-image/form_frist.png";
import md from "../../chatbot-image/mdi_email.png";
import outline from "../../chatbot-image/mdi_outline.png";
import chatt from "../../chatbot-image/chatimg.png";
import check101210 from "../../chatbot-image/demo_material-symbols_check.png";
import Emailpage from "../Website/emailsection.js";

function Pricing() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    number: "",
    discraption: "",
    account_name: "",
    features: {
      Ticketing: false,
      Call: false,
      LiveChat: false,
      SocialMedia: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormdata((prevData) => ({
        ...prevData,
        features: { ...prevData.features, [name]: checked },
      }));
    } else {
      setFormdata({ ...formdata, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/demos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (response.ok) {
        console.log("Post request successful");
      } else {
        console.error("Post request failed");
      }
    } catch (error) {
      console.error("Error making post request", error);
    }
  };

  return (
    <>
      <div className="container-fluid .bg-gradient-custom chat_page_all">
        <div className="w-full">
          <div className="container m-auto">
            <div className=" w-full row lg:flex">
              <div className="lg:w-1/2 pt-10 ">
                <div className="form_css py-8 bg-[#fff]">
                  <form onSubmit={handleSubmit} className="">
                    <div className="pl-4  ">
                      <div className="text-[#00023F] text-2xl font-bold font-family: 'Poppins', sans-serif;">
                        Schedule a demo
                      </div>
                      <div className=" ">
                        <div className="Number_of_agents md:flex w-full">
                          <div className="md:w-1/2">
                            <img
                              className="image_setting"
                              src={frist_input}
                              alt=""
                            />
                            <input
                              className=" my-2 start_form pl-9"
                              type="text"
                              name="name"
                              placeholder="name"
                              onChange={handleChange}
                              value={formdata.name}
                            />
                          </div>

                          <div className="demo_sedule  md:w-1/2">
                            <img
                              className="image_gmail"
                              src={md}
                              alt=""
                              width={"100px"}
                            />
                            <input
                              className="input_massage  my-2 start_form pl-9"
                              type="text"
                              name="email"
                              placeholder="Enter your email "
                              onChange={handleChange}
                              value={formdata.email}
                            />
                          </div>
                        </div>
                        <div className="Number_of_agents md:flex w-full relative">
                          <div className="md:w-1/2">
                            <span className="absolute py-6 px-3">
                              <svg
                                className="fill-[#DF673A]"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                              >
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                              </svg>
                            </span>
                            <input
                              className=" my-2 start_form pl-9"
                              type="text"
                              name="number"
                              placeholder="number"
                              onChange={handleChange}
                              value={formdata.number}
                            />
                          </div>

                          <div className="demo_sedule  md:w-1/2">
                            <img
                              className="image_gmail"
                              src={outline}
                              alt=""
                              width={"100px"}
                            />
                            <input
                              className="input_massage  my-2 start_form pl-9"
                              type="text"
                              name="account_name"
                              placeholder="Enter company name "
                              onChange={handleChange}
                              value={formdata.account_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-3">
                        <h1 className="text-[#494949] capitalize font-family: 'Poppins', sans-serif;">
                          What features do you need?
                          <span className="text-[#DF673A] mx-1">*</span>
                        </h1>
                        <div className="md:flex gap-2 images_main my-2 ">
                          <div className="flex items-center lg:mb-4  ">
                            <input
                              type="checkbox"
                              name="Ticketing"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
            rounded focus:ring-blue-500
             dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 
             dark:bg-gray-700 dark:border-gray-600"
                              onChange={handleChange}
                              value={formdata.tickting}
                            />

                            <label
                              className="ml-2 text-sm font-medium text-[#7E7E7E] 
           dark:text-gray-300 font-family: 'Poppins', sans-serif;"
                            >
                              Ticketing
                            </label>
                          </div>
                          <div className="flex items-center lg:pl-10 lg:mb-4 ">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 
             bg-gray-100 border-gray-300 rounded focus:ring-blue-500 
             dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 
             dark:bg-gray-700 dark:border-gray-600 "
                            />
                            <label
                              className="ml-2 text-sm font-medium text-[#7E7E7E] 
           dark:text-gray-300 font-family: 'Poppins', sans-serif;"
                            >
                              Call
                            </label>
                          </div>
                          <div className="flex items-center lg:pl-10 lg:mb-4 boostr">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 bg-gray-100
             border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
             dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 
             dark:border-gray-600"
                            />
                            <label
                              className="ml-2 text-sm font-medium text-[#7E7E7E]
            dark:text-gray-300 font-family: 'Poppins', sans-serif;"
                            >
                              Live Chat
                            </label>
                          </div>
                          <div className="flex items-center lg:pl-10 lg:mb-4  ">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-blue-600
             bg-gray-100 border-gray-300 rounded focus:ring-blue-500 
             dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 
             dark:bg-gray-700 dark:border-gray-600    
             "
                            />
                            <label
                              className="ml-2 text-sm font-medium text-[#7E7E7E] 
           dark:text-gray-300 font-family: 'Poppins', sans-serif;"
                            >
                              Social media
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" text-[#494949] capitalize">
                        <h1>
                          Number of agents{" "}
                          <span className="text-[#DF673A] font-family: 'Poppins', sans-serif;">
                            *
                          </span>
                        </h1>
                        <div className="flex Number_of_agents py-2 ">
                          <div className="w-1/2">
                            <div className="Aunxnxdi"></div>
                            <div className="wrap ">
                              <select className="one  text-[#494949] text-[15px] font-family: 'Poppins', sans-serif; ">
                                <option selected="selected">Number</option>
                                <option>01-09</option>
                                <option>10-20</option>
                                <option>21-30</option>
                                <option>31-40</option>
                              </select>
                            </div>
                          </div>
                          <div className="Aunxnxdi"></div>
                          <div className="w-1/2">
                            <div className="">
                              <div className="wrap ">
                                <select className="two text-[#494949] text-[15px] font-family: 'Poppins', sans-serif;">
                                  <option selected="selected ">Language</option>
                                  <option>HINDI</option>
                                  <option>ENGLISH</option>
                                  <option>PUNJABI</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h1 className="text-[#494949] py-2 capitalize font-family: 'Poppins', sans-serif;">
                          How would you like to use Chat with your business?
                          <span className="text-[#DF673A] mx-1">*</span>
                        </h1>
                        <textarea
                          className="fild my-2 start_form check"
                          type="text"
                          name="discraption"
                          placeholder="massage "
                          onChange={handleChange}
                          value={formdata.discraption}
                        />
                      </div>
                      <div className="flex pt-1 pb-2 ">
                        <input
                          type="checkbox"
                          name=""
                          className="w-4 h-4 mt-1  bg-gray-100
                     border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                     dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 border-red-400	"
                        />
                        <h1 className="pl-3 text-[#7E7E7E] font-family: 'Poppins', sans-serif;">
                          This Is A Private Computer, Remember Me
                        </h1>
                      </div>
                      <div className="">
                        <button className="submit_btn font-bold font-family: 'Poppins', sans-serif; ">
                          Book Demo Slot For FREE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="lg:w-1/2 pt-10">
                <img className="m-auto" src={chatt} alt="" />
              </div>
            </div>
            <div className="mx-5  ">
              <div className="px-5 ">
                <h1 className="text-[25px] font-[700] capitalize text-[#00023F] font-family: 'Poppins', sans-serif;">
                  What you will learn during the demo
                </h1>
                <div className="flex my-4">
                  <div className="   him ">
                    <img src={check101210} />
                  </div>
                  <p className="text-[#494949] text-[17px] pl-2  capitalize font-family: 'Poppins', sans-serif;">
                    How to achieve your business goals with Chat – whether it’s
                    improving customer satisfaction or generating more sales.
                  </p>
                </div>
                <div className="flex my-4">
                  <div className="him ">
                    <img src={check101210} />
                  </div>
                  <p className="text-[#494949] text-[17px] pl-2 capitalize font-family: 'Poppins', sans-serif;">
                    How Chat works. We’ll give you a tour of the product so you
                    can get an idea of how it is to work with our software.
                  </p>
                </div>
                <div className="flex my-4">
                  <div className="him ">
                    <img src={check101210} />
                  </div>
                  <p className="text-[#494949] text-[17px] capitalize pl-2 font-family: 'Poppins', sans-serif;">
                    Answers to any questions you may have about pricing,
                    service, integrations, and features.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Emailpage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
