import React, { useState } from "react";
import md from "../../chatbot-image/mdi_email.png";
import Group from "../../img/Group11.png";
import outline from "../../chatbot-image/mdi_outline.png";
import mtt from "../../chatbot-image/mat.png";
import frist_input from "../../chatbot-image/form_frist.png";
import Emailpage from "../Website/emailsection.js";

const Trail = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const date = Date.now().toString();
      const password = date + name;

      const response = await fetch("/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_Name: name,
          email: email,
          phone: null, // Assuming phone is optional
          password: password,
          confirm_password: password,
          company_name: companyName,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("Free account created successfully!");
      } else {
        console.error("Registration failed:", responseData);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return (
    <section>
      <div className="container-fluid bg-gradient-to-b from-[#D7E4E4] chat_page_all">
        <div className="w-full">
          <div className="container m-auto">
            <div className="w-full row lg:flex py-5">
              {/* Form Section */}
              <div className="lg:w-1/2 trail_res lg:mx-12 border-2 rounded border-[#3639A4] lg:py-10 lg:px-10 bg-[#ffff]">
                <div>
                  <h1 className="text-[25px] font-[950] text-[#00023F] py-2">
                    Start Your{" "}
                    <span className="text-[#FF6800]">Free Trial</span> Today
                  </h1>
                  <p className="py-1 text-[#595959] text-[16px]">
                    Experience working with Chat for free with our 7-day or
                    30-day trial. You can enjoy every feature available in the
                    Large plan for free without any strings attached, and select
                    your new chat plan at the end of your trial.
                  </p>
                </div>
                <div className="md:flex py-1 gap-2">
                  <div className="md:w-1/2 cursor-pointer hover:shadow-md  origin-center	">
                    <div
                      className="bg-[#FEEBE4;] flex items-center justify-center py-3"
                      style={{ backgroundColor: "#FEEBE4" }}
                    >
                      <div className="w-[15px] h-[15px] rounded-full bg-[#DF673A]"></div>
                      <h1 className="text-center mx-2 text-[#4D4D4D]">
                        7 or 30 day free trial
                      </h1>
                    </div>
                  </div>
                  <div className="md:w-1/2 cursor-pointer md:mt-0 mt-2 hover:shadow-md  origin-center	">
                    <div
                      className="bg-[#E3E4FF] flex items-center justify-center py-3"
                      style={{ backgroundColor: "#E3E4FF" }}
                    >
                      <h1 className="text-center mx-2 text-[#4D4D4D]">
                        No Credit Card required
                      </h1>
                    </div>
                  </div>
                </div>
                {/* Input Fields */}
                <div>
                  <img className="image_setting" src={frist_input} alt="" />
                  <input
                    className="input_trail my-2 pl-9 border-[#FF6800]"
                    type="text"
                    value={name}
                    placeholder="Enter your name*"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <img className="image_gmail" src={md} alt="" width="100px" />
                  <input
                    className="input_trail my-2 pl-9"
                    type="email"
                    value={email}
                    placeholder="Enter your email*"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="relative">
                  <img
                    className="image_gmail"
                    src={outline}
                    alt=""
                    width="100px"
                  />
                  <input
                    className="input_trail my-2 pl-9"
                    type="text"
                    value={companyName}
                    placeholder="Company Name*"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="flex">
                  <input type="checkbox" className="w-4 h-4 mt-3 lg:mr-2" />
                  <h1 className="text-center my-2 text-[#7B7B7B] text-sm">
                    Send me product updates and other promotional offers.
                  </h1>
                </div>
                {/* Submit Button */}
                <div className="account">
                  <button
                    className="w-full rounded-sm h-14 bg-[#3639A4] text-[#FFFFFF] my-5 hover:bg-[#008596]"
                    onClick={handleSubmit}
                  >
                    Create Account for FREE
                  </button>
                </div>
                <div>
                  <h1 className="text-center text-[#7B7B7B] text-sm">
                    By signing up, I accept <u>T&C</u> and <u>Privacy Policy</u>
                    .
                  </h1>
                </div>
              </div>
              {/* Image Section */}
              <div className="lg:w-1/2 w-full flex relative">
                <div className="lg:flex relative lg:right-[25px]">
                  <img src={Group} alt="" width="" />
                </div>
                <div className="lg:absolute lg:top-0 right-0 lg:bottom-0">
                  <h1 className="font-bold text-[#434343]">
                    Handle all support channels in one solution
                  </h1>
                  <div className="flex pl-2 pt-4">
                    <div className="w-[20px] h-[20px] bg-[#FFFFFF]">
                      <img src={mtt} alt="" />
                    </div>
                    <p className="pl-2 font-bold text-[#3D3D3D]">
                      Spend 85% less time on issues
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Emailpage />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trail;
