import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client"; // Import Socket.IO client
import EmojiPicker from "emoji-picker-react";

// Replace with your server URL
const socket = io("http://89.117.144.11:5001", {
  transports: ["websocket"], // Force WebSocket transport
});

const WhatsAppChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Listen for incoming messages
    socket.on("receiveMessage", (message) => {
      setLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: prevMessages.length + 1, ...message },
      ]);
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("receiveMessage");
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    scrollToBottom();
  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (input.trim()) {
      const newMessage = {
        id: messages.length + 1,
        text: input,
        sender: "user",
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      socket.emit("sendMessage", { message: input, prompt: input });
      setLoading(true);
      setInput(""); // Clear input field after sending
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setInput((prevInput) => prevInput + emojiObject.emoji);
  };

  return (
    <div className="flex absolute right-12 bottom-24 z-10">
      <div
        className="fixed bottom-4 right-4 text-white rounded-full p-3 cursor-pointer shadow-lg"
        style={{ backgroundColor: "#f1278c" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-3xl">+</div>
      </div>

      {isOpen && (
        <div className="bottom-16 right-4 flex flex-col chat-custom-style bg-white shadow-lg rounded-lg w-80">
          <div
            className="flex items-center justify-between p-4 text-white rounded-t-lg"
            style={{ backgroundColor: "#f1278c" }}
          >
            <h1 className="text-lg font-bold">Chat</h1>
            <button onClick={() => setIsOpen(false)} className="text-white">
              X
            </button>
          </div>
          <div
            className="flex-1 p-4 overflow-y-auto custom-scrollbar"
            ref={scrollRef}
          >
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                } my-4`}
              >
                <div
                  className={`p-2 rounded-lg ${
                    message.sender === "user" ? "sender-chat" : "received-chat"
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
            {loading && (
              <div className="flex justify-start my-4">
                <div className="chatgptloader"></div>
              </div>
            )}
          </div>
          <form
            onSubmit={sendMessage}
            className="flex p-2 border-t border-gray-300"
          >
            <div className="relative flex-1">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1 p-2 border rounded-lg w-full focus:outline-none"
                placeholder="Type a message..."
              />
              <button
                type="button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                😊
              </button>
              {showEmojiPicker && (
                <div className="absolute bottom-14 z-10">
                  <EmojiPicker
                    height={350}
                    width={380}
                    onEmojiClick={handleEmojiClick}
                  />
                </div>
              )}
            </div>
            <button
              type="submit"
              style={{ backgroundColor: "#f1278c" }}
              className="ml-2 text-white rounded-lg px-4 py-2"
            >
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

const App = () => {
  return <WhatsAppChat />;
};

export default App;
