import React from "react";
const TermAndCondition = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen py-10 bg-gray-100">
        <div className="bg-white shadow-lg rounded-md p-8 max-w-5xl mx-4">
          <h1 className="text-3xl font-bold mb-4" style={{ color: "#000" }}>
            Terms & Conditions
          </h1>
          <p className="text-sm text-gray-600 mb-4">
            Last updated on July 20th 2024
          </p>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Introduction
            </h3>
            <p className="text-gray-700">
              Welcome to ChatPro. These Terms and Conditions govern your use of
              our website. By accessing or using ChatPro, you agree to comply
              with and be bound by these Terms. If you do not agree with these
              Terms, please do not use our website.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Use of the Website
            </h3>
            <p className="text-gray-700">
              You must use ChatPro in compliance with all applicable laws and
              regulations. You agree not to use ChatPro for any unlawful purpose
              or in any way that could harm, disable, overburden, or impair the
              website.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              User Accounts
            </h3>
            <p className="text-gray-700">
              To access certain features of ChatPro, you may need to create an
              account. You are responsible for maintaining the confidentiality
              of your account information and for all activities that occur
              under your account.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Job Listings
            </h3>
            <p className="text-gray-700">
              Employers may post job listings on ChatPro. Job listings must be
              accurate, not misleading, and comply with all applicable laws.
              ChatPro reserves the right to remove any job listing that violates
              these Terms.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Candidate Applications
            </h3>
            <p className="text-gray-700">
              Candidates may apply for jobs listed on ChatPro. By applying for a
              job, you consent to the sharing of your application and related
              information with the employer.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Content
            </h3>
            <p className="text-gray-700">
              You are responsible for any content you post on ChatPro. You grant
              ChatPro a non-exclusive, worldwide, royalty-free license to use,
              reproduce, modify, and display your content in connection with the
              operation of the website.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Privacy
            </h3>
            <p className="text-gray-700">
              Your use of ChatPro is also governed by our Privacy Policy. Please
              review the Privacy Policy to understand our practices.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Limitation of Liability
            </h3>
            <p className="text-gray-700">
              ChatPro is provided "as is" without warranties of any kind.
              ChatPro is not liable for any damages arising from your use of the
              website.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Changes to Terms
            </h3>
            <p className="text-gray-700">
              We may modify these Terms at any time. Your continued use of
              ChatPro after any changes constitutes your acceptance of the new
              Terms.
            </p>
          </div>

          <div>
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Contact Us
            </h3>
            <p className="text-gray-700">
              If you have any questions about these Terms, please contact us at{" "}
              <a href="mailto:info@ChatPro.com">info@ChatPro.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermAndCondition;
