// Message.js
import React from "react";

const Message = ({ user, text, timestamp }) => {
  return (
    <div
      className={`flex items-start mb-4 ${
        user === "user" ? "justify-end" : "justify-start"
      }`}
    >
      {/* Show Assistant's Avatar on the Left */}
      {user != "user" && (
        <div className="w-10 h-10 rounded-full mr-2">
          <img
            src="https://api.hirepros.co.uk/uploads/deafultman.png"
            alt="Assistant"
            className="w-full h-full object-cover rounded-full"
          />
        </div>
      )}
      {/* Message Box */}
      <div
        className={`chat-box text-black p-2 rounded-lg max-w-xs ${
          user === "user" ? "sender-chat" : "received-chat"
        }`}
      >
        <div>
          <p>{text}</p>
          <p className="text-xs mt-1 text-gray-500">{timestamp}</p>
        </div>
      </div>
      {/* Show User's Avatar on the Right */}
      {user == "user" && (
        <div className="w-10 h-10 rounded-full mr-2">
          <img
            src="https://api.hirepros.co.uk/uploads/deafultman.png"
            alt="Assistant"
            className="w-full h-full object-cover rounded-full"
          />
        </div>
      )}
    </div>
  );
};

export default Message;
