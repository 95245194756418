import React from "react";
import moment from "moment";

const ChatList = ({ chats, onSelectChat }) => {
  return (
    <div
      className="h-full overflow-y-auto p-4"
      style={{ background: "#281a34", color: "#fff" }}
    >
      <h2 className="text-xl font-bold mb-4">Chats</h2>
      <ul>
        {chats.map((chat) => {
          console.log(chat);

          const lastMessage =
            chat.messages && chat.messages.length > 0
              ? chat.messages[chat.messages.length - 1]
              : null;

          return (
            <li
              key={chat.id}
              className="p-2 cursor-pointer  rounded"
              onClick={() => onSelectChat(chat)}
            >
              <div className="flex">
                <div className="w-10 h-10 rounded-full mr-2">
                  <img
                    src="https://api.hirepros.co.uk/uploads/deafultman.png"
                    style={{ borderRadius: "40px" }}
                  />
                </div>
                <div className="ana-message-chat">
                  {/* Display job title */}
                  <p className="font-semibold">{chat.userName}</p>
                  <p className="text-sm text-gray-600 truncate whitespace-pre-line">
                    {chat.lastMessage || "No messages yet"}
                  </p>
                  {/* Display time of the last message */}
                  {lastMessage ? (
                    <p className="text-sm text-gray-500">
                      {moment(lastMessage.createdAt).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-500">No messages yet</p>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatList;
