import React, { useState } from "react";
import { FaArrowRight, FaEnvelope } from "react-icons/fa";
import victorImage from "../../chatbot-image/Vector.png";
import "../../css/emailsection.css";

const Emailsection = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSignup = () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }
    setMessage("Thank you for signing up!");
    // Send email to the backend API here.
    setEmail("");
  };

  return (
    <footer className="anlybiz-section-footer container mx-auto">
      <div className="anlybiz-section-container">
        <div className="anlybiz-section-content">
          <h2 className="anlybiz-section-heading lg:pl-20 pl-0">
            Always Available To Assist You With{" "}
            <span className="anlybiz-section-highlight">Any Issue</span>
          </h2>
          <div className="anlybiz-section-email hidden xl:block">
            <img
              src={victorImage}
              alt="Assistance icon to show support availability"
              className="anlybiz-section-vector"
            />
          </div>
        </div>
        <div className="anlybiz-section-signup">
          <h3 className="anlybiz-section-signup-title">Newsletter Signup</h3>
          <div className="anlybiz-section-input-container-main">
            {" "}
            <div className="anlybiz-section-input-container">
              <FaEnvelope className="anlybiz-section-icon" />
              <input
                type="email"
                placeholder="Email Address"
                className="anlybiz-section-email-input w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
              />
            </div>
            <div className="">
              {" "}
              <button
                className="anlybiz-section-submit-button"
                onClick={handleSignup}
                aria-label="Submit email"
              >
                <svg
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill="#fff"
                >
                  <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
                </svg>
              </button>
            </div>
          </div>
          {message && <p className="anlybiz-section-message">{message}</p>}
        </div>
      </div>
    </footer>
  );
};

export default Emailsection;
