import React, { useState } from "react";
import { getUserData } from "../../helpers/utils";
const CodeSnippet = () => {
  const [copiedIframe, setCopiedIframe] = useState(false);
  const [copiedScript, setCopiedScript] = useState(false);
  const User = getUserData();
  const analybiz = "//89.117.144.11";
  const userId = User?._id;
  const iframeCode = `
    <div class="chat-launcher"><svg width="60" height="60" viewBox="0 0 60 60"  xmlns="http://www.w3.org/2000/svg">
<path fill="#fff" d="M30.7 48.0749C32.46 47.9499 34.1967 47.6691 35.91 47.2324C38.0184 47.8643 40.2429 48.008 42.415 47.6524C42.5013 47.6413 42.588 47.6346 42.675 47.6324C43.45 47.6324 44.4675 48.0774 45.95 49.0149V47.4749C45.9504 47.2066 46.0227 46.9433 46.1594 46.7125C46.2962 46.4816 46.4924 46.2917 46.7275 46.1624C47.3725 45.8049 47.9725 45.3874 48.52 44.9324C50.68 43.1249 51.9 40.7174 51.9 38.1674C51.8992 37.3242 51.765 36.4863 51.5025 35.6849C52.1525 34.4849 52.6825 33.2199 53.07 31.9099C54.32 33.7599 54.995 35.9349 55 38.1674C55 41.6324 53.365 44.8674 50.5375 47.2299C50.0645 47.6234 49.5677 47.9875 49.05 48.3199V51.9249C49.05 53.1649 47.6 53.8749 46.5775 53.1399C45.6101 52.4256 44.609 51.7581 43.5775 51.1399C43.2809 50.9656 42.9727 50.8119 42.655 50.6799C41.805 50.8066 40.94 50.8708 40.06 50.8724C36.53 50.8724 33.2675 49.8274 30.7 48.0749ZM12.035 40.8599C7.575 37.1349 5 32.0449 5 26.5999C5 15.4649 15.645 6.56494 28.6425 6.56494C41.64 6.56494 52.2875 15.4649 52.2875 26.5999C52.2875 37.7349 41.64 46.6324 28.6425 46.6324C27.1808 46.6324 25.7408 46.5216 24.3225 46.2999C23.71 46.4424 21.2625 47.8774 17.735 50.4199C16.46 51.3424 14.645 50.4524 14.645 48.8999V42.7499C13.7311 42.1847 12.8589 41.5548 12.035 40.8649M24.41 42.5099C24.515 42.5099 24.6233 42.5183 24.735 42.5349C26.0265 42.7491 27.3334 42.8561 28.6425 42.8549C39.6225 42.8549 48.41 35.5074 48.41 26.5999C48.41 17.6924 39.6225 10.3474 28.6425 10.3474C17.6675 10.3474 8.875 17.6949 8.875 26.5999C8.875 30.9074 10.9275 34.9624 14.5575 37.9974C15.4742 38.7608 16.4708 39.4449 17.5475 40.0499C18.15 40.3874 18.5225 41.0124 18.5225 41.6874V45.2349C21.3125 43.3849 23.1475 42.5099 24.4075 42.5099" />
<path fill="#fff" d="M19.0625 30C19.8913 30 20.6862 29.6708 21.2722 29.0847C21.8583 28.4987 22.1875 27.7038 22.1875 26.875C22.1875 26.0462 21.8583 25.2513 21.2722 24.6653C20.6862 24.0792 19.8913 23.75 19.0625 23.75C18.2337 23.75 17.4388 24.0792 16.8528 24.6653C16.2667 25.2513 15.9375 26.0462 15.9375 26.875C15.9375 27.7038 16.2667 28.4987 16.8528 29.0847C17.4388 29.6708 18.2337 30 19.0625 30ZM29.2175 30C30.0463 30 30.8412 29.6708 31.4272 29.0847C32.0133 28.4987 32.3425 27.7038 32.3425 26.875C32.3425 26.0462 32.0133 25.2513 31.4272 24.6653C30.8412 24.0792 30.0463 23.75 29.2175 23.75C28.3887 23.75 27.5938 24.0792 27.0078 24.6653C26.4217 25.2513 26.0925 26.0462 26.0925 26.875C26.0925 27.7038 26.4217 28.4987 27.0078 29.0847C27.5938 29.6708 28.3887 30 29.2175 30ZM39.375 30.0025C40.2038 30.0025 40.9987 29.6733 41.5847 29.0872C42.1708 28.5012 42.5 27.7063 42.5 26.8775C42.5 26.0487 42.1708 25.2538 41.5847 24.6678C40.9987 24.0817 40.2038 23.7525 39.375 23.7525C38.5462 23.7525 37.7513 24.0817 37.1653 24.6678C36.5792 25.2538 36.25 26.0487 36.25 26.8775C36.25 27.7063 36.5792 28.5012 37.1653 29.0872C37.7513 29.6733 38.5462 30.0025 39.375 30.0025Z" />
</svg></div>
    <div class="chat-wrapper">
      <iframe src="http:${analybiz}:8003/?id=${userId}" title="Chat Window"></iframe>
    </div>
  `;
  const scriptCode = `
      <link
      rel="stylesheet"
      href="http:${analybiz}assets/css/iframe.css"
    />
    <script>
      document.addEventListener("DOMContentLoaded", function () {
        const chatLauncher = document.querySelector(".chat-launcher");
        const chatWrapper = document.querySelector(".chat-wrapper");
        chatLauncher.addEventListener("click", function () {
          chatWrapper.classList.toggle("is-open");
        });
      });
    </script>
  `;
  const handleCopy = (code, setCopied) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
  };

  return (
    <div className="p-4 bg-gray-100 border border-gray-300 rounded-md xl:max-w-4xl lg:max-w-3xl md:max-w-2xl  mx-auto shadow-md space-y-6">
      <div>
        <h2
          className="text-lg font-semibold mt-5 mb-3"
          style={{ color: "#00023f" }}
        >
          Copy your iframe code And Put it into body tag:
        </h2>
        <textarea
          className="w-full p-3 bg-gray-50 border border-gray-300 rounded-md text-sm font-mono resize-none custom-scrollbar"
          value={iframeCode}
          readOnly
          rows={6}
        />
        <button
          onClick={() => handleCopy(iframeCode, setCopiedIframe)}
          className="mt-3 px-4 py-2  text-white font-medium rounded-md  focus:outline-none focus:ring-2  focus:ring-offset-2"
          style={{ backgroundColor: "#ff6800" }}
        >
          {copiedIframe ? "Copied!" : "Copy to Clipboard"}
        </button>
      </div>
      <div>
        <h2
          className="text-lg font-semibold mt-5 mb-3"
          style={{ color: "#00023f" }}
        >
          Copy your script code And Put it into head tag:
        </h2>
        <textarea
          className="w-full p-3 bg-gray-50 border border-gray-300 rounded-md text-sm font-mono resize-none custom-scrollbar"
          value={scriptCode}
          readOnly
          rows={10}
        />
        <button
          onClick={() => handleCopy(scriptCode, setCopiedScript)}
          className="mt-3 px-4 py-2  text-white font-medium rounded-md  focus:outline-none focus:ring-2  focus:ring-offset-2"
          style={{ backgroundColor: "#ff6800" }}
        >
          {copiedScript ? "Copied!" : "Copy to Clipboard"}
        </button>
      </div>
    </div>
  );
};

export default CodeSnippet;
