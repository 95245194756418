// Login.js
import React, { useState } from "react";
import api from "../../Api";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../image/logo-change.png";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { PopupProvider } from "../Common/PopupContext";
import "../../App.css";

// import FeatherIcon from "feather-icons-react";

//import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [selectedRole, setSelectedRole] = useState("Applicant");

  function handleClick(showArray, hideArray) {
    showArray.map((id) => {
      document.getElementById(id).style = "display:block";
    });
    hideArray.map((id) => {
      document.getElementById(id).style = "display:none";
    });
  }
  function start() {
    document.querySelector("#step_2").classList.add("dv-step_2-show");
  }
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { email, password };
      console.log(userData);
      const response = await api.Login(userData);
      console.log(response);
      const { token, refreshToken } = response;
      if (response.status !== 400) {
        // toast.success("Login Successfully", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        const { accessToken, refreshToken, _id } = response;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userId", _id);
        console.log("Login successful:", response);
        history.push("/dashboard");
        // Redirect or perform other post-login actions here
      } else {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        // Handle unexpected response status
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };
  const socialLogin = async (e) => {
    e.preventDefault();
    try {
      window.location.href = `${api.socialLogin}`;
    } catch (error) {
      console.error(error);
    }
  };
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      try {
        const userData = { name, email, password, phone, company };
        const response = await api.signup(userData);
        if (response.status !== 400) {
          history.push("/");
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleforgot = () => {
    window.location.reload();
    // history.push("/forgotpassword");
  };

  return (
    <div>
      <div className="login_inform login_inform-page ">
        <div className="flex justify-center items-center login_inform-inner">
          <div className="  form_upto m-8 loginDiv-page">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              <div className="mb-1 text-center">
                <div className="text-center ChatPro-login-heading ">
                  <img
                    className="m-auto"
                    src={logo}
                    alt="logo"
                    width={"100px"}
                  />
                  <h2 className="pt-8  ">Hi, Welcome Back !</h2>
                  <h4>still don't have an account? Sign up</h4>
                </div>
              </div>
              {/* Display error message */}
              {isMessage && (
                <button className="btn-loginmessage" type="text" danger>
                  {isMessage}
                </button>
              )}
              <form
                className="login-form-section animated-form"
                onSubmit={handleSubmit}
              >
                <div className="mb-1 form-group">
                  <input
                    className="  border rounded w-full py-2 px-3 text-gray-700  "
                    type="text"
                    id="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email" className="Email-label">
                    Email
                  </label>
                </div>
                <div className="mb-2 form-group">
                  <input
                    id="password"
                    className="  border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="password-label">
                    Password
                  </label>
                </div>
                <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600"
                      // onChange={onChange}
                    />
                    <span className="ml-2 text-gray-700">
                      Keep me logged in
                    </span>
                  </label>
                  <NavLink
                    className="forgot-pass-link color"
                    to="/forgotpassword"
                    onClick={() => handleforgot()}
                  >
                    Forgot Password?
                  </NavLink>
                </div>
                <div>{isMessage != "null" ? isMessage : ""}</div>
                <div className="">
                  <button
                    className="login-form-btn bg-[#000] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {isLoading ? "Loading..." : "Log in"}
                  </button>
                </div>
                <div className="line-after-login my-2">
                  <div className="line-after-login-inner">
                    <div className="line-after-login-first"></div>
                    <div className="line-after-login-or">or</div>
                    <div className="line-after-login-second"></div>
                  </div>
                </div>
                <div className="mb-6">
                  <button
                    className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={socialLogin}
                  >
                    {isLoading ? "Loading..." : "Sign in with google"}
                  </button>
                </div>
                <div className="keepForget text-center">
                  <p>
                    Don't have an account?
                    <span
                      onClick={(e) => {
                        handleClick(["step_2"], ["step_1"]);
                        start();
                      }}
                    >
                      <NavLink
                        className="forgot-pass-link color cursor-pointer	"
                        to="/register"
                      >
                        Signup
                      </NavLink>
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
