import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const ChatPrompt = () => {
  const User = getUserData();
  const toastRef = useRef();
  const [prompt, setPrompt] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [editingPromptId, setEditingPromptId] = useState(null); // To track editing
  const userId = User?._id; // Example userId, replace with real user ID
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // Fetch prompts for the user
    const fetchPrompts = async () => {
      try {
        const response = await axios.get(`${domainpath}/api/prompts/${userId}`);
        setPrompts(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching prompts:", error);
        toastRef.current.addToast(
          error.response?.data?.message || "Failed to fetch prompts",
          "error"
        );
      }
    };
    fetchPrompts();
  }, [userId]);

  const savePrompt = async () => {
    setLoading(true);
    try {
      if (editingPromptId) {
        // Update existing prompt
        const response = await axios.put(
          `${domainpath}/api/prompts/${editingPromptId}`,
          {
            content: prompt,
          }
        );
        setPrompts(
          prompts.map((p) =>
            p._id === editingPromptId
              ? { ...p, content: response.data.content }
              : p
          )
        );
        toastRef.current.addToast("Prompt updated successfully!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        // Create a new prompt
        const response = await axios.post(`${domainpath}/api/prompts`, {
          userId,
          content: prompt,
          defaultPrompt: false, // Default is false when creating a new prompt
        });
        setPrompts([...prompts, response.data.prompt]);
        toastRef.current.addToast("Prompt saved successfully!", "success");
      }
      setPrompt(""); // Clear input
      setEditingPromptId(null); // Reset editing state
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error saving/updating prompt:", error);
      toastRef.current.addToast(
        error.response?.data?.message || "Failed to save/update prompt",
        "error"
      );
    }
  };

  const toggleDefaultPrompt = async (id, isDefault) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${domainpath}/api/prompts/${id}/default`,
        {
          defaultPrompt: !isDefault,
        }
      );
      setPrompts(
        prompts.map((p) =>
          p._id === id
            ? { ...p, defaultPrompt: response.data.prompt.defaultPrompt }
            : p
        )
      );
      setLoading(false);
      toastRef.current.addToast(
        `Prompt updated to ${
          !isDefault ? "default" : "non-default"
        } successfully!`,
        "success"
      );
    } catch (error) {
      console.error("Error updating default prompt:", error);
      toastRef.current.addToast(
        error.response?.data?.message || "Failed to update prompt",
        "error"
      );
      setLoading(false);
    }
  };

  const deletePrompt = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${domainpath}/api/prompts/${id}`);
      setPrompts(prompts.filter((p) => p._id !== id));
      setLoading(false);
      toastRef.current.addToast("Prompt deleted successfully!", "success");
    } catch (error) {
      setLoading(false);
      console.error("Error deleting prompt:", error);
      toastRef.current.addToast(
        error.response?.data?.message || "Failed to delete prompt",
        "error"
      );
    }
  };

  const startEditing = (id, content) => {
    setEditingPromptId(id); // Set the ID of the prompt being edited
    setPrompt(content); // Load the content into the textarea
  };

  const cancelEditing = () => {
    setEditingPromptId(null); // Clear editing state
    setPrompt(""); // Reset the textarea
  };

  return (
    <div className="flex flex-col">
      <textarea
        value={prompt}
        rows={10}
        onChange={(e) => setPrompt(e.target.value)}
        className="flex-1 p-4 border rounded-lg focus:outline-none resize-none"
        placeholder="Enter your chat prompt here..."
        style={{ height: "100%", width: "100%", fontSize: "16px" }}
      />
      <div className="flex gap-4 mt-4">
        <button
          onClick={savePrompt}
          className={`${
            editingPromptId ? "bg-blue-600" : "bg-green-600"
          } text-white rounded-lg px-4 py-2`}
        >
          {editingPromptId ? "Update Prompt" : "Save Prompt"}
        </button>
        {editingPromptId && (
          <button
            onClick={cancelEditing}
            className="bg-gray-500 text-white rounded-lg px-4 py-2"
          >
            Cancel Editing
          </button>
        )}
      </div>
      <div className="mt-4">
        {prompts.map((p) => (
          <div
            key={p._id}
            className="flex justify-between items-center border p-2 mb-2"
          >
            <div>
              <span>{p.content}</span>
              <input
                type="checkbox"
                checked={p.defaultPrompt}
                onChange={() => toggleDefaultPrompt(p._id, p.defaultPrompt)}
                className="ml-4"
              />
              <label className="ml-2 text-sm text-gray-700">Default</label>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => startEditing(p._id, p.content)}
                className="bg-yellow-600 text-white px-2 py-1 rounded"
              >
                Edit
              </button>
              <button
                onClick={() => deletePrompt(p._id)}
                className="bg-red-600 text-white px-2 py-1 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ChatPrompt;
